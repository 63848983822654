// Add your JS customizations here
jQuery(document).ready(function($) { 
	//set up the window width
 	const wSize = $(window).width();

 	//inizialize WOW
 	new WOW().init();

 	//enables Font Awesome pseudo elements
 	window.FontAwesomeConfig = {
 		searchPseudoElements: true
 	}

 	//add active class to body
 	$('body').addClass('active');


 	$('.matchheight').matchHeight();

 	if( $('body').hasClass('single-team_member') ){

 		let imgFixed = document.querySelector( '#img-fixed' );
		let callback = ( entries, observer ) => {
			entries.forEach( entry => {
				imgFixed.style.zIndex = entry.isIntersecting ? '-1' : '1';
				imgFixed.style.opacity = entry.isIntersecting ? '0' : '1';
			} )
		}

		let observer = new IntersectionObserver( callback, {
			threshold: [ 0 ] // If 50% of the element is in the screen, we count it!
			// Can change the thresholds based on your needs. The default is 0 - it'll run only when the element first comes into view
		} );


		[ 'end_fixed_scroll' ].forEach( d => {
			const div = document.getElementById( d );
			if ( div ) observer.observe( div );
		} );

 	}
 	if( $('body').hasClass('single-post') || $('body').hasClass('blog') || $('body').hasClass('archive')){

 		let imgFixed = document.querySelector( '#fixed-sidebar' );
		let callback = ( entries, observer ) => {
			entries.forEach( entry => {
				imgFixed.style.zIndex = entry.isIntersecting ? '-1' : '1';
				imgFixed.style.opacity = entry.isIntersecting ? '0' : '1';
			} )
		}

		let observer = new IntersectionObserver( callback, {
			threshold: [ 0 ] // If 50% of the element is in the screen, we count it!
			// Can change the thresholds based on your needs. The default is 0 - it'll run only when the element first comes into view
		} );


		[ 'end_fixed_scroll' ].forEach( d => {
			const div = document.getElementById( d );
			if ( div ) observer.observe( div );
		} );

 	}
 
 	//Variables
 	const homeHero = $('.home_hero');
 	let homeHeroHeight = $(homeHero).outerHeight(true);
 	let leftBarHeight =  $('.left-menu-sidebar').outerHeight(true);
 	let open = false; //flag
 	let open_sub = false; //flag

 		 // $(leftBarHeight).css('height', homeHero);

 			$(window).resize(function() {

 				 $(leftBarHeight).css('height', '100vh');

 			});

 	//MENU FUNCTIONS
 	$('.hamburger').click(function() {
         $(this).toggleClass('is-active');
         $('body').toggleClass('modal-open');
         $('.toggle_sub').attr('aria-expanded',false);
         if(  $('.sub_modal').is(":visible") ){
         	$('.sub_modal').slideUp(800);
         	$('.sub_modal').removeClass('open-sub-menu');
         }
         if(  $('.sub_menu').is(":visible") ){
         	  $('.sub_menu').slideUp(800);
         	  $('.sub_menu').parents('li').removeClass('open-sub-menu');
         } 
         $('#main-modal').slideToggle(800);
         
     });

 	$('#main-modal .menu-principale > .open-main a').click(function() {
 		let target = $(this).attr('href');
 		$(target).show(800);
 	});
 	$('.go-back').click(function(e) {
 		e.preventDefault();
 		$(this).parents('.modal-menu').hide(800);
 	});

 	$('#treatments-menu > li > .link-open-sub').click(function(e) {
	    e.preventDefault();
	       if( $(this).parents('li').hasClass('open-sub-menu') ){
	       	 $(this).parents('li').removeClass('open-sub-menu');
	       	 $(this).parents('li').find('.sub_menu').slideUp(800);
	       }else{
	       	$('#treatments-menu > li ').removeClass('open-sub-menu');
	       	 $(this).parents('li').addClass('open-sub-menu');
	       	  $('#treatments-menu .sub_menu').slideUp(800);
	       	 $(this).parents('li').find('.sub_menu').slideDown(800);
	       }

	      // open = !open;
	   
	});

	$('#concerns-menu > li > .link-open-sub').click(function(e) {
	    e.preventDefault();
	     if( $(this).parents('li').hasClass('open-sub-menu') ){
	       	 $(this).parents('li').removeClass('open-sub-menu');
	       	 $(this).parents('li').find('.sub_menu').slideUp(800);
	       }else{
	       	  	$('#concerns-menu > li ').removeClass('open-sub-menu');
	       	 $(this).parents('li').addClass('open-sub-menu');
	       	 $(this).parents('li').addClass('open-sub-menu');
	       	 $('#concerns-menu .sub_menu').slideUp(800);
	       	 $(this).parents('li').find('.sub_menu').slideDown(800);
	       	 
	       }

	       open = !open;
	   
	});

	$('#main-menu-1 .open-sub-about .link-open-sub').click(function(e) {
		 if( $(this).parents('li').hasClass('open-sub-menu') ){
	       	 $(this).parents('li').removeClass('open-sub-menu');
	       	 $(this).parents('li').find('.about_submenu').slideUp(800);
	       }else{
	       	$(this).parents('.open-sub-about').removeClass('open-sub-menu');
	       	 $(this).parents('li').addClass('open-sub-menu');
	       	 $('.about_submenu').slideUp(800);
	       	 $(this).parents('li').find('.about_submenu').slideDown(800);
	       	 
	       }
	       open_sub = !open_sub;
		
	});
	//menu functions end

	//Open book consultation pop up
	$('.open-consultation').click(function(e) {
		e.preventDefault();
		$('.book-a-consultation').toggleClass('open');
	});
	$('.bg-overlay').click(function(e) {
		e.preventDefault();
		$('.book-a-consultation').removeClass('open');
	});


	$('.social-mob-open').click(function(e) {
		e.preventDefault();
		$('.social-mob').toggleClass('open');
	});
	

	// the event needs to be run before slick is initialized
	$('.before_after_slider').on('init', function (event, slick, direction) {

	    // check to see if there are one or less slides
	    if (!($('.slick-slide').length > 1)) {

	        // remove arrows
	        $('.slider-btns').hide();

	    }

	});

	//Slick sldiers
	$('.treatments_carousel').slick({
 			dots: false,
 			slidesToShow: 3,
 			slidesToScroll: 1,
 			infinite: true,
 			arrows: false,
		    autoplay: true,
            autoplaySpeed: 4000,
 			responsive: [{
 				breakpoint: 992,
 				settings: {
 					slidesToShow: 1,
 					slidesToScroll: 1,
 					speed: 300,
 					centerMode: true,
  					variableWidth: true,
 				}
 			}]
 		});

	$('.team_member_slide').slick({
 			dots: false,
 			slidesToShow: 4,
 			slidesToScroll: 1,
 			//centerMode: true,
 			infinite: true,
 			arrows: false,
		    autoplay: true,
            autoplaySpeed: 4000,
 			responsive: [{
 				breakpoint: 992,
 				settings: {
 					slidesToShow: 1,
 					slidesToScroll: 1,
 					speed: 300,
 					centerMode: true,
  					variableWidth: true,
 				}
 			}]
 		});

	$('.treatments_carousel_single').slick({
			dots: false,
			slidesToShow: 2,
			slidesToScroll: 1,
			//centerMode: true,
			infinite: true,
			arrows: false,	   
		 autoplay: true,
           autoplaySpeed: 4000,
			responsive: [{
				breakpoint: 660,
				settings: {
					slidesToShow: 2,
					rows: 2,
					slidesToScroll: 1,
					speed: 300,
					vertical:true,
					//centerMode: true,
 					//variableWidth: true,
				}
			}]
		});


	$('.concerns_carousel_single').slick({
 			dots: false,
 			slidesToShow: 2,
 			slidesToScroll: 1,
 			//centerMode: true,
 			infinite: true,
 			arrows: false,
		    autoplay: true,
            autoplaySpeed: 4000,
 			responsive: [{
 				breakpoint: 660,
 				settings: {
 					rows:2,
 					vertical:true,
 					slidesToShow: 2,
 					slidesToScroll: 1,
 					speed: 300,
 					
 				}
 			}]
 		});

	//Randomize order function
	$.fn.randomize = function(selector){
	  var $elems = selector ? $(this).find(selector) : $(this).children(),
		      $parents = $elems.parent();

		  $parents.each(function(){
		      $(this).children(selector).sort(function(){
		          return Math.round(Math.random()) - 0.5;
		      }).detach().appendTo(this);
		  });

		  return this;
		};

	// randomize the slides and then call slick
	$('.testimonials').find('.reviews-slider').randomize('.item');

		$('.testimonials').find('.reviews-slider').slick({
 			dots: false,
 			slidesToShow: 1,
 			slidesToScroll: 1,
 			arrows: true,
 			autoplay: true,
 			autoplaySpeed: 7000,
 			prevArrow: $('.test-slick-prev'),
 			nextArrow: $('.test-slick-next'),
 		});

 		$('.before_after_slider').slick({
	 			slidesToScroll: 1,
	 			slidesToShow: 1,
	 			arrows: true,
	 			dots: false,
	 			infinite: true,
	 			prevArrow: $('.ba_prev'),
	 			nextArrow: $('.ba_next'),
	 			responsive: [{
	 				breakpoint: 520,
	 				settings: {
	 					rows: 2,
	 					arrows: true,
	 					slidesToShow: 1,
	 					slidesToScroll: 1,
	 				}
	 			}]
	 		});


	$('.rel_team_members').slick({
 			dots: false,
 			slidesToShow: 3,
 			slidesToScroll: 1,
 			//centerMode: true,
 			infinite: true,
 			arrows: false,
		    autoplay: true,
            autoplaySpeed: 5000,
 			responsive: [{
 				breakpoint: 992,
 				settings: {
 					slidesToShow: 1,
 					slidesToScroll: 1,
 					speed: 300,
 					//centerMode: true,
  					//variableWidth: true,
 				}
 			}]
 		});

	
 	$(".treatments_container .item").mouseenter(function() {
		    $(this).addClass('active');
		}).mouseleave(function() {
		    $(this).removeClass('active');
		});

 	if( $('body').hasClass('single-treatment') || $('body').hasClass('single-concerns')  ){
		//$('#treatments_carousel_single').slick(options);
 		const buttonSwitch = $('#featured_treatments .switch');
 		const relatedInner = $('.related-inner');
 		$(buttonSwitch).click(function(e) {
 			let dataId = $(this).attr('data-id');
 			let target = '#' +  dataId;;
 			$(buttonSwitch).removeClass('active');
 			//$('.slick-slider').slick('refresh');
 			$(this).addClass('active');
 			$(relatedInner).hide('slow');
 			setTimeout(function () {
 				$('.slick-slider').slick('refresh');
 				$(target).show('fast');
 			}, 1000);

 			//$('#treatments_carousel_single').slick('unslick');
  			//$('#treatments_carousel_single').slick(options);
 			//	setTimeout(function () { 
 			//		$(relatedInner).hide('slow');
 			//		$(target).show('slow');
 			//	}, 1500);
 				
 		});

		 	//ACCORDIONS -TODO: to chenge with the live URL
		 	const arrowDown = '<img src="https://drpjaestheticclinic.com/wp-content/themes/dr-pj-blow/img/arrow-down.png">';
		 	const arrowUp = '<img src="https://drpjaestheticclinic.com/wp-content/themes/dr-pj-blow/img/arrow-up.png">';
		 	$('.accordion .toggle').click(function(e) {
		 		e.preventDefault();
		 		var $this = $(this);
		 		if ($this.parents('.accordion').find('.inner').hasClass('show')) {
		 			$this.parents('.accordion').find('.inner').removeClass('show');
		 			$this.parents('.accordion').find('.inner').slideUp(350);
		 			$this.parents('.accordion').removeClass('open');
		 			//$this.find('span').html('<i class="fal fa-chevron-down"></i>');
		 			$this.find('span').html(arrowDown);

		 		} else {
		 			$this.parents('.accordion').find('.inner').addClass('show');
		 			$this.parents('.accordion').find('.inner').slideDown(350);
		 			$this.parents('.accordion').addClass('open');
		 			//$this.find('span').html('<i class="fal fa-chevron-up"></i>');
		 			$this.find('span').html(arrowUp);
		 		}
		 	});
 	}

 	if( $('body').hasClass('page-template-products') ){
 		const arrowUpGold = '<img src="https://drpjaestheticclinic.com/wp-content/uploads/2022/07/arrow-gold-up.png">';
 		const arrowDownGold = '<img src="https://drpjaestheticclinic.com/wp-content/uploads/2022/07/arrow-gold-down.png">';
 		$('.accordion .toggle').click(function(e) {
		 	e.preventDefault();
		 	var $this = $(this);
		 	if ($this.parents('.accordion').find('.inner').hasClass('show')) {
		 		$this.parents('.accordion').find('.inner').removeClass('show');
		 		$this.parents('.accordion').find('.inner').slideUp(350);
		 		$this.parents('.accordion').removeClass('open');
		 		$this.find('span').html(arrowDownGold);

		 	} else {
		 		$this.parents('.accordion').find('.inner').addClass('show');
		 		$this.parents('.accordion').find('.inner').slideDown(350);
		 		$this.parents('.accordion').addClass('open');
		 		$this.find('span').html(arrowUpGold);
		 	}
		 });

 	}

 	if( $('body').hasClass('single-team_member') ){

 	//ACCORDIONS
		 	const arrowDown = '<img src=https://drpjaestheticclinic.com/wp-content/themes/dr-pj-blow/img/arrow-down-gold.png">';
		 	const arrowUp = '<img src="https://drpjaestheticclinic.com/wp-content/themes/dr-pj-blow/img/arrow-up-gold.png">';
		 	$('.accordion .toggle').click(function(e) {
		 		e.preventDefault();
		 		var $this = $(this);
		 		if ($this.parents('.accordion').find('.inner').hasClass('show')) {
		 			$this.parents('.accordion').find('.inner').removeClass('show');
		 			$this.parents('.accordion').find('.inner').slideUp(350);
		 			$this.parents('.accordion').removeClass('open');
		 			//$this.find('span').html('<i class="fal fa-chevron-down"></i>');
		 			$this.find('h3').html('View More');
		 			$this.find('span').html(arrowDown);

		 		} else {
		 			$this.parents('.accordion').find('.inner').addClass('show');
		 			$this.parents('.accordion').find('.inner').slideDown(350);
		 			$this.parents('.accordion').addClass('open');
		 			//$this.find('span').html('<i class="fal fa-chevron-up"></i>');
		 			$this.find('span').html(arrowUp);
		 			$this.find('h3').html('View Less');
		 		}
		 	});
 	}


 	//Sidebar dropdowns
    $('#cat').select2({
	 		placeholder: "Category",
	 		minimumResultsForSearch: Infinity,
			allowClear: false,
			width: '100%',
		});

		 $('#archives-dropdown-2').select2({
	 		placeholder: "Archives",
	 		minimumResultsForSearch: Infinity,
			allowClear: false,
			width: '100%',
		});

		$('b[role="presentation"]').hide();

		$('.select2-selection__arrow').append('<div class="arrow"></div>');
 	
 		//SEARCH
	$( '.open_search' ).click( function( e ) {
			e.preventDefault();
			const modalTarget = $( this ).data( 'target' );
			$( modalTarget ).toggleClass( 'show' );
			$(this).toggleClass('open');
		} );

		$( '.close-modal' ).click( function( e ) {
			e.preventDefault();
			$( '#search-modal' ).removeClass( 'show' );
		} );


		  // On Page Load
    $(window).on('load', function () {

    });


});
